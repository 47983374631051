<template>
  <a-drawer :visible="visible" width="50%" :maskClosable="false" @close="handleClose">
    <a-form-model :model="entity" @submit.prevent="handleSave">
      <a-form-model-item
        label="名称"
        prop="name"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-input v-model="entity.title" allowClear></a-input>
      </a-form-model-item>
      
       <a-form-model-item
        label="分享"
        prop="key"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-input v-model="entity.key" allowClear></a-input>
       </a-form-model-item>
        <a-form-model-item
        label="路径"
        prop="path"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-input v-model="entity.path" allowClear></a-input>
       </a-form-model-item>
      <a-form-model-item
        label="状态"
        prop="enabled"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-switch v-model="entity.enabled" />
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 8, offset: 4 }">
        <a-space>
          <a-button htmlType="submit" :loading="loading" type="primary">
            保存
          </a-button>
          <a-button :loading="loading" @click="handleClose"> 取消 </a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>


<script>
import ShareRecordService from "../../../services/share-record";
const defaultEntity = {};
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    target: {
      type: Object,
    },
  },
  data() {
    return {
      service: ShareRecordService,
      entity: JSON.parse(JSON.stringify(defaultEntity)),
    };
  },
  watch: {
    target: {
      handler: function name(value) {
        this.entity = {
          ...defaultEntity,
          ...value,
        };

        if (!this.entity.properties) {
          this.entity.properties = {};
        }

        if (!this.entity.document) {
          this.entity.document = {};
        }
      },
      deep: true,
    },
  },
  methods: {
    handleClose() {
      this.entity = JSON.parse(JSON.stringify(defaultEntity));

      this.$emit("close");

      this.$emit("update:visible", false);
      this.$emit("update:loading", false);
    },
    async handleSave() {
      try {
        this.$emit("update:loading", true);
        if (!this.entity.id) {
          await this.service.addEntity(this.entity);
        } else {
          await this.service.updateEntity(this.entity);
        }
      } catch (error) {
        this.$message.error(
          error.response ? error.response.data.message : error.message
        );
      } finally {
        this.entity = JSON.parse(JSON.stringify(this.entity));

        this.$emit("close");
        this.$emit("update:visible", false);
        this.$emit("update:loading", false);
      }
    },
  },
};
</script>

<style>
</style>